import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { BidderTenderUiDto } from "../../models/tender/BidderTenderUiDto";

export const TenderActions = createActionGroup({
  source: 'Tender',
  events: {
    'Set Bidder Tender Ui Dto': props<{ bidderTenderUiDto: BidderTenderUiDto }>(),
    'Get Bidder Tender Ui Dto': emptyProps(),
    'Clear Bidder Tender Ui Dto': emptyProps(),
  }
})