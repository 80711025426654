import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'baseUrl'
})
export class BaseUrlPipe implements PipeTransform {
  serverUrl: string = environment.baseUrl;

  transform(url: any): string {
    if (url && typeof url === 'string') {
      // Check if the URL is a base64 image
      if (url.startsWith('data:image')) {
        // If it's a base64 image, return it as is
        return url;
      } else {
        // For regular URLs, prepend the base URL
        return `${this.serverUrl}${url}`;
      }
    } else {
      return url;
    }
  }

}
