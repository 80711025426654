import { createFeature, createReducer, on } from "@ngrx/store";
import { OrganizationUIDto } from "../../models/OrganizationUIDto";
import { OrganizationActions } from "./organization.actions";

interface State {
  organizationUiDto: OrganizationUIDto | null;
}

const initialState: State = {
  organizationUiDto: null
};

export const organizationFeature = createFeature({
  name: 'organization',
  reducer: createReducer(
    initialState,
    on(OrganizationActions.saveOrganizationUiDto, (state, { organizationUiDto }) => ({
      ...state,
      organizationUiDto,
    })),
    on(OrganizationActions.getOrganizationUiDto, (state) => ({
      ...state,
    })),
    on(OrganizationActions.clearOrganizationUiDto, (state) => ({
      ...state,
      organizationUiDto: null,
    })),
  )
});

export const {
  name,
  reducer,
  selectOrganizationState,
  selectOrganizationUiDto
} = organizationFeature