import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  _sessionExpired$ = new BehaviorSubject<boolean>(false);

  showSessionExpiredDialog: boolean = true;

  constructor(
    private router: Router,
  ) { }

  get getSessionExpired$() { return this._sessionExpired$.asObservable(); }

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);

      if (error.status == 401 || this.isRedirectedToRoot(error)) {
        this._sessionExpired$.next(true);
        // this.modelService.dismissAll()

        if (this.showSessionExpiredDialog) {
          this.showSessionExpiredDialog = false;
          localStorage.clear();

          // let modalRef = this.modelService.open(MessagePopupComponent);
          // modalRef.componentInstance.message = 'Session Expired';

          setTimeout(() => {
            // this.modelService.dismissAll();
          }, 1000);
        }

        this.router.navigate(['/'], { skipLocationChange: true })
      } else if (error.name == "TimeoutError") {
        alert("Request Timeout, Try Again!")
      } else {
        if (error.status != 504) {
          // this.errorHandlerService.sendErrorToServer(`${operation}`, error);
        }
      }

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    }
  }

  public returnNull<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    }
  }

  isRedirectedToRoot(error: any) {
    if (error.error && error.error.text) {
      if (error.error.text.includes("<app-root></app-root>")) {
        return true;
      }
    }
    return false;
  }

  setResetDialogFlag() {
    this.showSessionExpiredDialog = true;
    this._sessionExpired$.next(false);
  }
}
