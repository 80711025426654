import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {}
  
  ngOnInit(): void {
    this.router.events.subscribe(() => {
      // let currentPath = this.activatedRoute.snapshot.firstChild?.routeConfig?.path || '';
      // if (currentPath) {
      //   console.log(currentPath);
      // }
    });
  }
}
