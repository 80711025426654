import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { StepsModule } from 'primeng/steps';
import { TreeModule } from 'primeng/tree';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PaginatorModule } from 'primeng/paginator';
import { MenuModule } from 'primeng/menu';

const MODULES = [
  CommonModule,
  DialogModule,
  TableModule,
  StepsModule,
  TreeModule,
  InputTextModule,
  DialogModule,
  TableModule,
  DynamicDialogModule,
  ProgressSpinnerModule,
  AutoCompleteModule,
  ButtonModule,
  ToastModule,
  CalendarModule,
  ConfirmDialogModule,
  PaginatorModule,
  MenuModule
];

@NgModule({
  declarations: [
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    ...MODULES
  ]
})
export class PrimengModule { }
