<header *ngIf="headerName == 'ONBOARDING'">
  <div class="flex flex-row w-full items-center gap-3 px-3 head-pos main-header">
    <div class="">
      <a>
        <img [src]="'/downloadLandingBlob?fileId=' + organizationUiDto?.logo?.fileId | baseUrl"
          [alt]="organizationUiDto?.name" class="application-logo | img-fluid my-1 ms-md-5"
          *ngIf="organizationUiDto?.logo" />
        <img src="assets/logo/procurext-logo.svg" alt="Procurext" class="application-logo | img-fluid my-1"
          *ngIf="!organizationUiDto?.logo" />
      </a>
    </div>
  <div class=" flex justify-center items-center w-full flex-col"> 
    <div class="text-base font-semibold text-gray-700">Supplier Information</div>
    <div class="text-xs font-medium text-gray-500">{{bidderUiDto?.primaryEmailId}}</div>
   </div>
   </div> 
</header>

<header *ngIf="headerName == 'DEFAULT'">
  <div class="flex flex-row w-full justify-between items-center gap-3 px-3 head-pos main-header ">
    <div class="">
      <a>
        <img [src]="'/downloadLandingBlob?fileId=' + organizationUiDto?.logo?.fileId | baseUrl"
          [alt]="organizationUiDto?.name" class="application-logo | img-fluid my-1 ms-md-5"
          *ngIf="organizationUiDto?.logo" />
        <img src="assets/logo/procurext-logo.svg" alt="Procurext" class="application-logo | img-fluid my-1"
          *ngIf="!organizationUiDto?.logo" />
      </a>
    </div>
    <div class="border h-10 border-gray-100  mx-2"></div>
    <div class="basis-6/12">
      <div class="flex flex-row items-center justify-start my-2 px-3">
        <div class="basis-1/2 inline-flex items-center mt-2">
          <span class="inline-flex text-xs font-normal text-black underline"> Home</span>
          <span class="mx-2">/</span>
          <span class="inline-flex text-xs font-normal text-black underline">Tender management</span>
          <span class="mx-2">/</span>
          <span class="inline-flex text-xs font-normal text-blue-500 ">Settings</span>
        </div>
      </div>
    </div>
    <div class="basis-5/12 text-end">
      <ul class="flex float-end items-center">
        <li class="mt-1">
          <span pTooltip="Notification" tooltipPosition="bottom" placeholder="Bottom" class="mr-3 c-pointer " container="body"
            placement="auto">
            <i-lucide name="bell" color="#6b7280"  [size]="24" class="my-icon inline-flex"></i-lucide>
          </span>
        </li>
        <li class="mt-1">
          <span pTooltip="Help" tooltipPosition="bottom" placeholder="Bottom" class="mr-3 c-pointer " container="body"
            placement="bottom">
            <i-lucide name="circle-help" color="#6b7280" [size]="24" class="my-icon inline-flex"></i-lucide>
          </span>
        </li>
        
        <li class="mr-3">
          <div class="border h-6 border-gray-200  mx-2"></div>
        </li>
        <li class="mr-3 c-pointer relative">
          <span>
            <p-menu #menu [model]="menuItems" [popup]="true" styleClass="bg-white" appendTo="body">
              <ng-template pTemplate="item" let-menuItem>
                <div class="p-menuitem-link p-3 grid  hover:bg-slate-100" (click)="logout()">
                  <div class=" font-medium text-xs items-center flex">
                    <i-lucide name="log-out" color="#4B5563" [size]="16" class="my-icon inline-flex me-1"></i-lucide>
                    <span class="text-gray-600">Logout</span>
                  </div>
                </div>
                <div class="border border-gray-50 px-0 w-full"></div>
                <div class="p-menuitem-link p-3 grid  hover:bg-slate-100">
                  <div class=" font-medium text-xs items-center flex">
                    <i-lucide name="list-restart" color="#4B5563" [size]="16" class="my-icon inline-flex me-1"></i-lucide>
                    <span class="text-gray-600">Reset Password</span>
                  </div>
                </div>
                <div class="border border-gray-50 px-0 w-full"></div>
                <div class="p-menuitem-link p-3 grid  hover:bg-slate-100">
                  <div class=" font-medium text-xs items-center flex">
                    <i-lucide name="user-pen" color="#4B5563" [size]="16" class="my-icon inline-flex me-1"></i-lucide>
                    <span class="text-gray-600"> View Profile</span>
                  </div>
                </div>
                <div class="border border-gray-50 px-0 w-full"></div>
              </ng-template>
            </p-menu>
  
            <span class="cursor-pointer"  (click)="menu.toggle($event)">
              <!-- <img [src]="'/downloadLandingBlob?fileId=' + userUiDto?.profileImage?.fileId | baseUrl"
                alt="{{userUiDto?.firstName}}" class="header-image header-image-border"
                *ngIf="userUiDto?.profileImage"> -->
                <div class="text-xs bg-blue-300 font-semibold w-6 h-6 flex items-center justify-center rounded-full ">
                  {{bidderUiDto?.firstName + ' ' + bidderUiDto?.lastName | textAvatar}}
                </div>
              <!-- <img src="./assets/images/avatar_default.png" alt="{{userUiDto?.firstName}}"
                class="header-image header-image-border" *ngIf="!userUiDto?.profileImage"> -->
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</header>