import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { GooglePlacesService } from '../../services/google-places.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-places-autocomplete',
  templateUrl: './places-autocomplete.component.html',
  styleUrls: ['./places-autocomplete.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlacesAutocompleteComponent),
      multi: true,
    },
  ],
})
export class PlacesAutocompleteComponent implements ControlValueAccessor {
  places: google.maps.places.AutocompletePrediction[] = [];
  selectedPlace: google.maps.places.PlaceResult | null = null;
  searchAddress: string = '';

  @Output() onAddressChange = new EventEmitter<google.maps.places.PlaceResult>()

  constructor(
    private googlePlacesService: GooglePlacesService,
    private cdRef: ChangeDetectorRef
  ) { }

  // ControlValueAccessor methods
  writeValue(value: string): void {
    this.searchAddress = value;
    this.cdRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Handle disabled state
  }

  // Internal methods for ControlValueAccessor
  onChange = (value: string) => { };
  onTouched = () => { };

  search(event: { query: string }) {
    this.googlePlacesService.getPlacePredictions(event.query).subscribe({
      next: (predictions: google.maps.places.AutocompletePrediction[]) => {
        this.places = predictions;
        this.cdRef.detectChanges();
      },
      error: (error: any) => {
        console.error('Error fetching place predictions:', error);
      }
    });
  }

  onSelect(event: { value: google.maps.places.AutocompletePrediction }) {
    const placeId = event.value.place_id;
    if (placeId) {
      this.googlePlacesService.getPlaceDetails(placeId).subscribe({
        next: (place: google.maps.places.PlaceResult) => {
          this.selectedPlace = place;
          this.searchAddress = place.formatted_address || '';
          this.onChange(this.searchAddress); // Update the form control value
          this.onAddressChange.emit(place); // Emit the selected place
          this.cdRef.detectChanges();
        },
        error: (error) => {
          console.error('Error fetching place details:', error);
        }
      });
    }
  }
}

