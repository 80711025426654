import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { OrganizationUIDto } from '../../models/OrganizationUIDto';
import { filter, Subscription } from 'rxjs';
import { selectBidderUiDto } from '../../state-management/session/session.features';
import { BidderUiDto } from '../../models/BidderUiDto';
import { selectOrganizationUiDto } from '../../state-management/organization/organization.features';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {
  organizationUiDto?: OrganizationUIDto;
  bidderUiDto?: BidderUiDto;

  headerName: 'DEFAULT' | 'ONBOARDING' = 'DEFAULT';

  onboardingPages = ['basicDetails', 'bankDetails', 'addressDetails', 'documentDetails', 'dscDetails']

  menuItems = [
    { title: 'Edit', escape: false },
  ]

  organizationSubscription$?: Subscription;
  bidderSubscription$?: Subscription;
  routerSubscription?: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.bidderSubscription$ = this.store.pipe(select(selectOrganizationUiDto)).subscribe(organizationUiDto => {
      if (organizationUiDto) {
        this.organizationUiDto = organizationUiDto;
      } else {
        this.organizationUiDto = undefined;
      }
    })

    this.bidderSubscription$ = this.store.pipe(select(selectBidderUiDto)).subscribe(bidderUiDto => {
      if (bidderUiDto) {
        this.bidderUiDto = bidderUiDto;
      } else {
        this.bidderUiDto = undefined;
      }
    })

    this.routerSubscription = this.router.events.subscribe(() => {
      this.checkHeaderVisibility();
    });
  }

  checkHeaderVisibility() {
    // Get the current active route path
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // Now, route should be the last active route
    let currentPath = route.snapshot.routeConfig?.path || '';
    // console.log('Current Path:', currentPath);

    // Set the visibility of the header based on the path
    if (this.onboardingPages.includes(currentPath)) {
      this.headerName = 'ONBOARDING';
    } else {
      this.headerName = 'DEFAULT';
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe();
    }
    if (this.bidderSubscription$) {
      this.bidderSubscription$.unsubscribe();
    }
  }
}
