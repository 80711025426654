import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { SessionActions } from './session.actions';
import { SessionInfoDto } from '../../models/SessionInfoDto';

interface State {
  sessionInfo: SessionInfoDto | null;
}

const initialState: State = {
  sessionInfo: null
};

export const sessionFeature = createFeature({
  name: 'session',
  reducer: createReducer(
    initialState,
    on(SessionActions.saveSessionInfo, (state, { sessionInfo }) => ({
      ...state,
      sessionInfo
    })),
    on(SessionActions.getSessionInfo, (state) => ({
      ...state
    })),
    on(SessionActions.updateBidderUiDto, (state, { bidderUiDto }) => ({
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        bidderUiDto
      }
    })),
    on(SessionActions.clearSession, (state) => ({
      ...state,
      sessionInfo: null
    }))
  ),
  extraSelectors: ({ selectSessionInfo }) => {
    const selectBidderUiDto = createSelector(
      selectSessionInfo, (sessionInfoDto) => sessionInfoDto?.bidderUiDto
    );

    return { selectBidderUiDto }
  },
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectSessionState, // feature selector
  selectSessionInfo, // selector for `sessionInfoDto` property
  selectBidderUiDto,   // selector for `bidderUiDto` property
} = sessionFeature;