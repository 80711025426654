import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { BasicAuthHttpInterceptor } from './shared/interceptors/basic-auth-http.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { icons, LucideAngularModule } from 'lucide-angular';
import { sessionFeature } from './shared/state-management/session/session.features';
import { organizationFeature } from './shared/state-management/organization/organization.features';
import { SharedModule } from './shared/shared.module';
import { navigationFeature } from './shared/state-management/navigation/navigation.features';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { tenderFeature } from './shared/state-management/tender/tender.features';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    LucideAngularModule.pick(icons),
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature(sessionFeature),
    StoreModule.forFeature(organizationFeature),
    StoreModule.forFeature(navigationFeature),
    StoreModule.forFeature(tenderFeature),
  ],
  providers: [
    MessageService, DialogService, ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
