import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { OrganizationUIDto } from "../../models/OrganizationUIDto";

export const OrganizationActions = createActionGroup({
  source: 'organization',
  events: {
    'Save Organization Ui Dto': props<{ organizationUiDto: OrganizationUIDto }>(),
    'Get Organization Ui Dto': emptyProps(),
    'Clear Organization Ui Dto': emptyProps(),
  }
})