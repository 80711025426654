import { createFeature, createReducer, on } from "@ngrx/store";
import { TenderActions } from "./tender.actions";
import { BidderTenderUiDto } from "../../models/tender/BidderTenderUiDto";

interface State {
  bidderTenderUiDto: BidderTenderUiDto | null
}

const initialState: State = {
  bidderTenderUiDto: null
};

export const tenderFeature = createFeature({
  name: 'TenderFeature',
  reducer: createReducer(
    initialState,

    on(TenderActions.setBidderTenderUiDto, (state, { bidderTenderUiDto }) => ({
      ...state,
      bidderTenderUiDto
    })),
    on(TenderActions.getBidderTenderUiDto, (state) => ({
      ...state,
    })),
    on(TenderActions.clearBidderTenderUiDto, (state) => ({
      ...state,
      tenderUiDto: null,
    })),
  ),
});

export const {
  name,
  reducer,
  selectBidderTenderUiDto
} = tenderFeature