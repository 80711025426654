import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, pipe, Subscription } from 'rxjs';
import { selectBidderUiDto, selectSessionInfo } from './shared/state-management/session/session.features';
import { SessionActions } from './shared/state-management/session/session.actions';
import { ApplicationConstants } from './shared/utils/ApplicationConstants';
import { UserService } from './shared/services/user.service';
import { UserPrivilege } from './shared/enums/UserPrivilege';
import { ApplicationUtils } from './shared/utils/ApplicationUtils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
  title = 'procurext.ai-bidder-ui';

  isLoggedIn: boolean = false;

  routerSubscription?: Subscription;
  bidderUiDtoSubscription$?: Subscription;

  constructor(
    private router: Router,
    private store: Store,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.bidderUiDtoSubscription$ = this.store.pipe(select(selectBidderUiDto)).subscribe(bidderUiDto => {
      if (bidderUiDto) {
        this.isLoggedIn = bidderUiDto != null;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.disableAngularLoader();
    // await this.applicationListenerService.loadApplicationInitialData();
    // this.dataRedirectionService.disableApplicationDataLoader()
    // // if param is not present, consider it as a page refresh event
    // this.handleRedirectionWithSessionId();
  }

  private disableAngularLoader() {
    let element = document.getElementById('idForDataLoader');
    if (element) {
      element.style.display = 'none';
      console.log("Loader disable successfully.")
    }
  }

  private async handleRedirectionWithSessionId() {
    await this.userService.getUserSessionInfo();
    const bidderUiDto = await firstValueFrom(this.store.pipe(select(selectBidderUiDto)));

    if (bidderUiDto && bidderUiDto.userId) {
      if (ApplicationUtils.checkIfRoleIsAssigned(bidderUiDto.userPrivileges || [], UserPrivilege.BIDDER)) {
        this.router.navigate(['/Bidder/Home/'], { skipLocationChange: true })
      } else {
        this.router.navigate(['/'], { skipLocationChange: true })
      }
    } else {
      this.router.navigate(['/'], { skipLocationChange: true });
    }

    // if (sessionInfoDto) {
    //   await this.dataRedirectionService.initializeUserListeners();
    // }
    // this.appLoader?.closeLoaderIcon(ApplicationConstants.LOADER_NOTIFICATION);
    // this.dataRedirectionService.navigateToPage();

  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.bidderUiDtoSubscription$) {
      this.bidderUiDtoSubscription$.unsubscribe();
    }
  }
}
