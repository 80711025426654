import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationUtils } from '../utils/ApplicationUtils';

@Pipe({
  name: 'ordinalDate'
})
export class OrdinalDatePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return '';
    }

    let dateObj = new Date(value);

    if (isNaN(dateObj.getTime())) {
      dateObj = ApplicationUtils.convertDateFormat(value)!;
    }

    const date = dateObj.getDate();
    const suffix = this.getOrdinalSuffix(date);
    const month = dateObj.toLocaleString('en-US', { month: 'long' });
    const year = dateObj.getFullYear();

    return `${date}${suffix} ${month}, ${year}`;
  }

  private getOrdinalSuffix(day: number): string {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
