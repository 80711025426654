import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrimengModule } from './primeng/primeng.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LucideAngularModule } from 'lucide-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CountryCodeSelectComponent } from './components/country-code-select/country-code-select.component';
import { DialogService } from 'primeng/dynamicdialog';
import { GoogleMapsModule } from '@angular/google-maps';
import { PlacesAutocompleteComponent } from './components/places-autocomplete/places-autocomplete.component';
import { OrdinalDatePipe } from './pipes/ordinal-date.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  CountryCodeSelectComponent,
  PlacesAutocompleteComponent,
  TimeAgoPipe,
  OrdinalDatePipe,
  SafePipe
];

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  PrimengModule,
  LucideAngularModule,
  GoogleMapsModule
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    ...MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES
  ],
  providers: [
    DatePipe, OrdinalDatePipe, SafePipe, TimeAgoPipe
  ]
})
export class SharedModule { }
