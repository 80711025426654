import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { SessionActions } from '../state-management/session/session.actions';
import { catchError, firstValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { selectBidderUiDto } from '../state-management/session/session.features';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { BidderUiDto } from '../models/BidderUiDto';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { UserService } from './user.service';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public isLoading: boolean = false;

  constructor(
    private socketService: SocketService,
    private httpClient: HttpClient,
    private router: Router,
    private errorService: ErrorService,
    private messageService: MessageService,
    private userService: UserService,
    private store: Store
  ) { }

  async logout() {
    this.router.navigate(['/'], { skipLocationChange: true });

    let bidderUiDto = await firstValueFrom(this.store.select(selectBidderUiDto));
    await firstValueFrom(this.userService.logout(bidderUiDto?.primaryEmailId));

    localStorage.clear();
    this.errorService.setResetDialogFlag();
    this.store.dispatch(SessionActions.clearSession())
  }

  async initializeListenerAndDoRouting(sessionInfo: SessionInfoDto) {
    let bidderUiDto: BidderUiDto = sessionInfo.bidderUiDto!;

    if (sessionInfo?.nodeServerUrl) {
      this.socketService.connect(sessionInfo?.nodeServerUrl);
    }

    if (bidderUiDto.registrationCompleted) {
      this.router.navigate(['/Bidder/Home/'], { skipLocationChange: true });
    } else {
      this.router.navigate(["/userOnboarding"], { skipLocationChange: true });
    }
  }

  handleServerResponse(data: any) {
    let sessionInfo = data.sessionInfoDto as SessionInfoDto;
    this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));

    this.initializeListenerAndDoRouting(sessionInfo);
  }

  authenticate(username?: string, password?: string, otpId?: string): Observable<any> {
    if (!otpId) {
      otpId = "";
    }

    let headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + password + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response', withCredentials: true}).pipe(
      switchMap(apiResponseDto => {
        if (apiResponseDto.status === 200) {
          localStorage.setItem('AUC_SESSION_ID', apiResponseDto.body);

          return this.httpClient.get<any>('/userDetails', { headers, observe: 'response', withCredentials: true });
        }
        // If the first API response is not successful, throw an error or return an observable that does nothing
        return of(apiResponseDto);
      }),
      // Handle the userDetails API response
      map(userDetailsResponse => {
        if (userDetailsResponse.body.code === ApplicationConstants.SUCCESS_CODE) {
          let sessionInfo = <SessionInfoDto>userDetailsResponse.body.data.sessionInfoDto;
          this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
        }
        return userDetailsResponse.body;
      })
    )
  }

  doAuthentication(userName: string, password: string, otpId?: string) {
    this.authenticate(userName, password, otpId).subscribe({
      next: (apiResponseDto) => {
        this.isLoading = false;
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.handleServerResponse(apiResponseDto.data);
        } else {
          localStorage.clear();
          // appLoader.closeLoaderForcefully();
          this.store.dispatch(SessionActions.clearSession());

          if (apiResponseDto.code == "USER-107") {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Email Id Not Registered" });
          } else if (apiResponseDto.code == "USER-142") {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Account Disabled" });
          }
          else if (apiResponseDto.code == "USER-144") {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "You are not authorized to login" });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
          }

        }
      },
      error: (error) => {
        this.isLoading = false;
        // appLoader.closeLoaderForcefully();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Wrong Credentials" });
        localStorage.clear();
      }
    });
  }

  authenticateWithOtp(username: string, userOtp: string, otpId: string): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + userOtp + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('WORKFLOW', 'LOGIN_WITH_OTP');

    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response' }).pipe(
      map(
        apiResponseDto => {
          if (apiResponseDto.body.code == ApplicationConstants.SUCCESS_CODE) {
            // session info only when authentication is successful.
            let sessionInfo = <SessionInfoDto>apiResponseDto.body.data.sessionInfoDto;
            this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
            localStorage.setItem('AUC_SESSION_ID', apiResponseDto.body.data.sessionID);
          }
          return apiResponseDto.body;
        }
      )
    )
  }

  
}
