import { createFeature, createReducer, on } from "@ngrx/store";
import { NavigationActions } from "./navigation.actions";

interface State {
  authView: string;
}

const initialState: State = {
  authView: 'LOGIN'
};

export const navigationFeature = createFeature({
  name: 'navigation',
  reducer: createReducer(
    initialState,
    on(NavigationActions.setAuthView, (state, { authView }) => ({
      ...state,
      authView: authView
    })),
    on(NavigationActions.getAuthView, (state) => ({
      ...state
    })),
  )
});

export const {
  name,
  reducer,
  selectNavigationState,
  selectAuthView
} = navigationFeature