import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { DscNotificationEnum } from '../enums/DscNotificationEnum';
import { UserService } from './user.service';
import { MessageService } from 'primeng/api';
import { io, Socket } from 'socket.io-client';
import { BidderUiDto } from '../models/BidderUiDto';
import { selectBidderUiDto } from '../state-management/session/session.features';
import { CacheUpdateTypes } from '../utils/CacheUpdateTypes';
import { DscNotificationDto } from '../models/DscNotificationDto';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {
  private socket?: Socket;
  private serverUrl = '';
  private reconnectInterval = 2000; // 2 seconds
  private heartbeatInterval?: any;

  bidderUiDto?: BidderUiDto;

  constructor(
    private store: Store,
    private userService: UserService,
    private messageService: MessageService
  ) {
    this.handleVisibilityChange();
  }

  async loadUserUiDto() {
    this.bidderUiDto = await firstValueFrom(this.store.select(selectBidderUiDto));
  }

  reconnectSocket() {
    this.socket = io(`wss://${this.serverUrl}`, {
      transports: ['websocket'], // Ensure WebSocket is the primary transport
    });
  }

  connect(serverUrl: string) {
    this.serverUrl = serverUrl;

    // Load user ui dto
    this.loadUserUiDto();

    this.socket = io(`wss://${serverUrl}`, {
      transports: ['websocket'], // Ensure WebSocket is the primary transport
    });

    this.socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
      this.socket?.emit('register', { id: this.socket?.id, user: this.bidderUiDto?.primaryEmailId });

      // Start the heartbeat if it hasn't been started yet
      if (!this.heartbeatInterval) {
        this.startHeartbeat();
      }
    });

    this.socket.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
      this.socket?.emit('unregister', { id: this.socket?.id, user: this.bidderUiDto?.primaryEmailId });

      setTimeout(() => {
        if (!this.socket) this.connect(serverUrl);
      }, this.reconnectInterval); // Reconnect on error
    });

    this.socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      setTimeout(() => {
        if (!this.socket) this.connect(serverUrl);
      }, this.reconnectInterval); // Reconnect on error
    });

    this.socket.on('reconnect_attempt', () => {
      console.log('Reconnecting...');
    });

    this.socket.on('redisDashboardCacheUpdates', (message) => {
      console.log(message);
      this.handleRedisCacheUpdates(JSON.parse(message));
    })

    this.socket.on('DSC_CHANNEL', (message) => {
      console.log(message);
      this.handleDscUpdates(JSON.parse(message));
    })

    this.socket.on('pong', (message) => {
      console.log(message);
    })
  }

  private startHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.connected) {
        this.socket.emit('ping', { id: this.socket?.id, user: this.bidderUiDto?.primaryEmailId, timestamp: new Date().toLocaleString() }); // Send a ping message
      }
    }, 30000); // Send a ping every 30 seconds
  }

  private clearHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  private handleVisibilityChange() {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden && !this.socket?.connected) {
        console.log('Tab is active again, reconnecting socket...');
        if (this.socket) {
          this.socket?.connect();
        } else {
          this.reconnectSocket();
        }
      }
    });
  }

  private async handleRedisCacheUpdates(data: any) {
   
  }

  private async handleDscUpdates(dscNotificationDto: DscNotificationDto) {
    let userUiDto = await firstValueFrom(this.store.pipe(select(selectBidderUiDto)));

    if (userUiDto?.userId == dscNotificationDto.userId) {
      if (dscNotificationDto.actionType == DscNotificationEnum.DSC_CONNECTION_CONNECTED) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'DSC connected successfully!' });
      } else if (dscNotificationDto.actionType == DscNotificationEnum.DSC_ADDED) {
        await this.userService.getUserDetails();
      } else if (dscNotificationDto.actionType == DscNotificationEnum.DSC_CONNECTION_DISCONNECTED) {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Utility connection disconnected!' });
      }
    }
  }

  ngOnDestroy(): void {
    this.clearHeartbeat();
    if (this.socket) {
      this.socket.emit('unregister', { id: this.socket?.id, user: this.bidderUiDto?.primaryEmailId });
      this.socket.disconnect();
    }
  }
}
